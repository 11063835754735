<template>
  <div
    class="ContactForm"
    id="top"
  >

    <div class="wrap">
      <p
        class="title"
        ref="pageTop"
      >お問い合わせ</p>
      <table class="form">
        <tr>
          <th>企業名・屋号名</th>
        </tr>
        <tr>
          <td>
            <input
              type="text"
              v-model.trim="data.company"
              placeholder="例）株式会社アドタスカル"
            />
          </td>
        </tr>
        <tr>
          <th>
            担当者名
            <span>必須</span>
          </th>
        </tr>
        <tr>
          <td class="name_box">
            <input
              type="text"
              v-model.trim="data.name_sei"
              placeholder=" 姓"
            />
            <input
              type="text"
              v-model.trim="data.name_mei"
              placeholder=" 名"
            />
          </td>
        </tr>
        <tr>
          <th>
            返信用メールアドレス
            <span>必須</span>
          </th>
        </tr>
        <tr>
          <td>
            <input
              type="email"
              v-model.trim="data.email"
              placeholder=" 例) info@sample.com"
            />
          </td>
        </tr>
        <tr>
          <th>
            お問い合わせ区分
            <span>必須</span>
          </th>
        </tr>
        <tr>
          <td>
            <select
              name="category"
              v-model="data.kubun"
            >
              <option value="">選択してください</option>
              <option value="Google広告成果連携ツール">Google広告成果連携ツール</option>
              <option value="Facebook広告コンバージョンAPI連携ツール">Facebook広告コンバージョンAPI連携ツール</option>
              <option value="Yahoo!検索広告成果連携ツール">Yahoo!検索広告成果連携ツール</option>
              <option value="YDA広告成果連携ツール">YDA広告成果連携ツール</option>
              <option value="Microsoft広告成果連携ツール">Microsoft広告成果連携ツール</option>
              <option value="LAP成果連携ツール">LAP成果連携ツール</option>
              <option value="TikTok広告成果連携ツール">TikTok広告成果連携ツール</option>
              <option value="その他">その他</option>
              <!-- <option value="離脱防止ポップアップ">ポップアップ</option>
              <option value="オーバーレイ">オーバーレイ</option> -->
            </select>
          </td>
        </tr>
        <tr>
          <th>
            お問い合わせ内容詳細
            <span>必須</span>
          </th>
        </tr>
        <tr>
          <td>
            <textarea
              v-model.trim="data.comment"
              cols="30"
              rows="10"
              placeholder="お問い合わせ内容をご記入ください。"
            ></textarea>
          </td>
        </tr>
      </table>

      <div class="button_cover">
        <button @click="reg">確認</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";

export default {
  name: "ContactForm",
  props: {},
  data() {
    return {
      rt: store.state.rt,
      data: {
        company: "",
        name_sei: "",
        name_mei: "",
        email: "",
        kubun: "",
        comment: "",
      },
    };
  },
  components: {
  },
  created: function () {
    //セッションストレージがあれば戻す
    var test = sessionStorage.getItem("ConfirmData");
    if (test == null || test == "") {
      console.log("NULL・なしだね");
    } else {
      console.log("ストレージに何か入ってるっぽいから戻すね");
      this.data = JSON.parse(sessionStorage.getItem("ConfirmData"));
    }
  },
  mounted: function () {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  },
  methods: {
    goTop: function () {
      //ページトップへ
      const el = this.$refs.pageTop;
      el.scrollIntoView({ behavior: "smooth" });
    },
    reg: function () {
      sessionStorage.setItem("ConfirmData", JSON.stringify(this.data));

      if (this.data.name_sei == "") {
        alert("担当者名（姓）をご入力ください");
        return;
      }
      if (this.data.name_mei == "") {
        alert("担当者名（名）をご入力ください");
        return;
      }
      if (this.data.email == "") {
        alert("メールアドレスをご入力ください");
        return;
      } else {
        //一応Outlook.jpとhotmail.co.jp hotmail.comははじく
        console.log();
        if (
          this.data.email.indexOf("outlook.jp") != -1 ||
          this.data.email.indexOf("hotmail.co.jp") != -1 ||
          this.data.email.indexOf("hotmail.com") != -1
        ) {
          alert("outlook.jpやhotmailのメールはご利用いただけません");
          return;
        }
      }
      if (this.data.kubun == "") {
        alert("お問い合わせ区分を選択してください");

        return;
      }
      if (this.data.comment == "") {
        alert("お問い合わせ内容をご入力ください");
        return;
      }

      this.$router.push({ name: "contact-form-check" });
    },
  },
};
</script>

<style lang="scss" scoped>
.MAIN_IMG {
  img {
    width: 100%;
  }
}
.title {
  // margin-top: 100px;
  margin-block-start: 0;
  padding-top: 20px;
  font-size: 25px;
  margin-bottom: 50px;
  letter-spacing: 0.1em;
  margin-top: 40px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 30px;
    // margin-top: 80px;
  }
}
.form {
  width: 100%;
  text-align: left;
  border: 1px solid #ccc;
  border-collapse: collapse;
  input,
  textarea,
  select,
  option {
    font-size: 16px;
  }
  th {
    background-color: #f2f2f2;
    font-size: 18px;
    font-weight: normal;
    padding: 10px 25px;
    box-sizing: border-box;
    letter-spacing: 0.05em;
    span {
      font-size: 12px;
      letter-spacing: 0.2em;
      background-color: #f15e62;
      color: white;
      // font-weight: bold;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }
  td {
    padding: 25px;
    border-bottom: 1px solid #ccc;
    input {
      width: 60%;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px 15px;
      @media screen and (max-width: $max-width) {
        width: 100%;
        box-sizing: border-box;
      }
    }
    textarea {
      width: 60%;
      border: 1px solid #ccc;
      padding: 15px;
      box-sizing: border-box;
      @media screen and (max-width: $max-width) {
        width: 100%;
        box-sizing: border-box;
      }
    }
    select {
      width: 40%;
      padding: 10px 15px;
      border: 1px solid #ccc;
      color: #4d4d4d;
      @media screen and (max-width: $max-width) {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
  .name_box {
    display: flex;
    input {
      width: 28%;
      margin-left: 35px;
      @media screen and (max-width: $max-width) {
        width: 100%;
        box-sizing: border-box;
        margin-left: 10px;
      }
    }
    input:first-child {
      margin-left: 0;
    }
  }
}
.button_cover {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  button {
    background-color: #1c6db5;
    border: none;
    color: white;
    font-size: 20px;
    letter-spacing: 0.5em;
    width: 200px;
    height: 55px;
    border-radius: 50px;
    padding-left: 15px;
    cursor: pointer;
  }
  a:hover {
    opacity: 0.8;
  }
}
</style>
