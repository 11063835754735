<template>
  <div class="ContactFormCheck">
    <!-- </> -->
    
    <div class="wrap">
      <p class="title">メール送信完了</p>

      <p>
        メールの送信が完了いたしました。
        <br />担当者からのご連絡をお待ちください。
      </p>

      <div class="button_cover">
        <router-link to="/">
          <button>トップページへ戻る</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
// import  from "@/components/.vue";

export default {
  name: "ContactFormCheck",
  props: {},
  data() {
    return {
      rt: store.state.rt,
      item: {
        company_name: "株式会社アドタスカル",
        staff_name: "アドタスカル　太郎",
        mail_address: "info@sample.com",
        category: "オーバーレイ",
        text: "お問い合わせ内容が入る"
      }
    };
  },
  components: {
    
  }
};
</script>

<style lang="scss" scoped>
.button_cover {
  button {
    cursor: pointer;
  }
}
.MAIN_IMG {
  img {
    width: 100%;
  }
}
.title {
  // margin-top: 100px;
  margin-block-start:0;
  padding-top: 20px;
  font-size: 25px;
  margin-bottom: 50px;
  letter-spacing: 0.1em;
  margin-top: 40px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 30px;
    // margin-top: 80px;
  }
}
p {
  text-align: center;
  font-size: 18px;
}

.button_cover {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  button {
    border: 1px solid #ccc;
    color: #4d4d4d;
    font-size: 16px;
    width: 250px;
    height: 55px;
    border-radius: 50px;
    padding-left: 15px;
  }
  a:hover {
    opacity: 0.8;
  }
}
</style>
