<template>
  <div class="AboutAdtasukaru">
    <p class="b">
      「<span>アド</span>(広告)」に「<span>足す</span>」、成果を「<span>刈る</span>」＝「アド助かる」
    </p>

    <div class="about_img">
      <img class="pc" :src="rt + '/img/lp/about_adtasukaru.png'" alt="" />

      <div class="sp">
        <div class="parts">
          <img :src="rt + '/img/lp/ado.png'" alt="" />
          <p><span>広告</span>アド</p>
        </div>

        <img :src="rt + '/img/lp/plus.png'" alt="" />

        <div class="parts">
          <div class="tool_name">
            <p>ポップアップ</p>
            <p>オーバーレイ</p>
            <p>Google広告成果連携ツール</p>
          </div>
          <p><span>機能を</span>足す</p>
        </div>

        <img :src="rt + '/img/lp/plus.png'" alt="" />

        <div class="parts">
          <img :src="rt + '/img/lp/karu.png'" alt="" />
          <p><span>成果を</span>刈る</p>
        </div>

        <img :src="rt + '/img/lp/about_yajirushi.png'" alt="" />

        <div class="parts">
          <img :src="rt + '/img/lp/adotasukaru.png'" alt="" />
          <p>アド助かる</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
export default {
  name: "BusinessOwner",
  props: {},
  data() {
    return {
      rt: store.state.rt,
    };
  },
};
</script>

<style lang="scss" scoped>
.AboutAdtasukaru {
  padding-bottom: 70px;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  img {
    width: 97%;
    margin-bottom: 50px;
    @media screen and (max-width: $max-width) {
      width: 10%;
      margin-bottom: 30px;
    }
  }
  p {
    font-size: 39px;
    margin-block-start: 0;
    padding-bottom: 40px;
    @media screen and (max-width: $max-width) {
      width: 100%;
      font-size: 20px;
      padding-bottom: 40px;
      margin-block-end: 0;
    }
    span {
      color: #1c6db5;
    }
  }
}

.about_img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $max-width) {
    text-align: center;
    display: block;
  }
  .parts {
    p {
      font-size: 28px;
      font-weight: bold;
      color: #1c6db5;
      padding-bottom: 30px;
      span {
        font-size: 15px;
        margin-right: 10px;
      }
    }
    .tool_name {
      margin-bottom: 30px;
      p {
        @media screen and (max-width: $max-width) {
          font-size: 18px;
          color: #4d4d4d;
          background-color: white;
          border-radius: 5px;
          padding-bottom: 0;
          width: 75%;
          margin: 0 auto 12px;
        }
      }
    }
    img {
      @media screen and (max-width: $max-width) {
        width: 42%;
        margin-bottom: 10px;
      }
    }
  }
  .parts:last-child {
    p {
      @media screen and (max-width: $max-width) {
        width: 42%;
        padding-bottom: 60px;
        width: 100%;
      }
    }
  }
}
</style>